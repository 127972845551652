import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "union-types"
    }}>{`Union Types`}</h1>
    <hr></hr>
    <p>{`Sometimes you need more than one type or need to concrete values from any of existed type. A simple example when you need values from two different types is `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` function, which is waiting argument as `}<inlineCode parentName="p">{`number`}</inlineCode>{` or `}<inlineCode parentName="p">{`string`}</inlineCode>{` and repeat given argument `}<inlineCode parentName="p">{`n`}</inlineCode>{` times:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function repeat(target, times) {
  if (typeof target === "number") {
    return target ** times;
  }
  return target.repeat(times);
}
`}</code></pre>
    <p>{`You can define types like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function repeat(target: unknown, times: number): unknown {
  if (typeof target === "number") {
    return target ** times;
  }

  // Error: Property "repeat" does not exist in "unknown"
  return target.repeat(times);
}
`}</code></pre>
    <p>{`But you will have an Error, because you try to get property from `}<inlineCode parentName="p">{`unknown`}</inlineCode>{` type.
You can try to add one more `}<inlineCode parentName="p">{`if`}</inlineCode>{` statement like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function repeat(target, times) {
  if (typeof target === "number") {
    return target ** times;
  }
  if (typeof target === "string") {
    return target.repeat(times);
  }
  throw new TypeError(\`Expected string or number, got '\${typeof target}'.\`);
}

const result = repeat(false, 4);
`}</code></pre>
    <p>{`But, if you put a wrong type (not number or string) you will find an error in runtime.`}</p>
    <p>{`So, the solution is union types. Actually, union types is a union of all possible values for types which will be provided to union operator `}<inlineCode parentName="p">{`|`}</inlineCode>{`. You can define it by declaring sequence of needed types separated by '|':`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`let iCanBeAStringOrNumber: string | number = 2;
iCanBeAStringOrNumber = "Hello";

// Error: Type "false" is incompatible with type "number | string"
iCanBeAStringOrNumber = false;
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`When you defined union type you lost ability to use this type as concrete type.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const iCanBeAStringOrNumber: string | number = 2;

// Error: Type "number | string" is incompatible with type "number"
const value: number = iCanBeAStringOrNumber;

// Error: Parameter "number | string" is incompatible with type "bigint | number"
iCanBeAStringOrNumber * 4;
`}</code></pre>
    <p>{`With union types `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` function will look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function repeat(target: string | number, times: number): string | number {
  if (typeof target === "number") {
    return target ** times;
  }
  return target.repeat(times);
}

// Error: Type "false" is incompatible with type "number | string"
const result = repeat(false, 4);
`}</code></pre>
    <p>{`And you will see wrong execution in static time (while you are writing code in editor).`}</p>
    <p>{`Also, union types are usefull when you want to pick only concrete values from some type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function createResponse(status: "Success" | "Failed") {
  return { status };
}

let response = createResponse("Success");
response = createResponse("Failed");

// Error: Type "'Custom String'" is incompatible with type "'Failed' | 'Success'"
response = createResponse("Custom String");
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      